<template>
  <v-dialog v-model="dialog" min-width="86vh" max-width="90vh">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary lighten-2"
        icon
        v-bind="attrs"
        v-on="on"
        tooltip="hello"
      >
        <v-icon>mdi-map</v-icon>
      </v-btn>
    </template>

    <v-card>
      <div id="map" v-if="leaflet">
        <l-map
          v-if="leafletLoaded"
          ref="lmap"
          :zoom="7"
          :center="center"
          :options="{ minZoom: 5, maxZoom: 18 }"
          style="height: 600px; width: 100%; max-height: 100%"
          @click="handleLeafletClick"
          @ready="updateLayout"
        >
          <l-tile-layer :url="leafletUrl" :attribution="leafletAttribution" />

          <l-control>
            <v-card v-if="displayLegend" max-width="200">
              <v-card-title>
                {{ $t("map.picklocation.legend.title") }}
                <v-spacer />
                <v-btn icon @click="() => (displayLegend = false)">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <p>{{ $t("map.picklocation.legend.description.1") }}</p>
                <p>{{ $t("map.picklocation.legend.description.2") }}</p>
              </v-card-text>
            </v-card>
            <v-card v-else>
              <v-btn icon @click="() => (displayLegend = true)">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-card>
          </l-control>

          <l-marker v-for="(m, i) in markers" :key="i" :lat-lng="m.position">
            <l-popup>
              {{ m.resource?.name ?? "" }} <br />
              {{ $t("map.currentPosition") }}
            </l-popup>
          </l-marker>

          <l-marker
            v-if="currentLatLng && infoWindowStatus"
            :lat-lng="currentLatLng"
            :draggable="true"
            @dragend="updateDrag"
          >
            <l-tooltip
              :options="{ permanent: true, reactive: true, interactive: true }"
            >
              <v-container style="width: 100%; height: 100%; padding: 0">
                <v-card flat light>
                  <v-card-text>
                    <h3>
                      {{ $t("common.pickLocationConfirm") }}
                    </h3>
                  </v-card-text>
                  <v-card-text>
                    <v-btn color="blue darken-1" text @click="updatePos">
                      {{ $t("common.save") }}
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-container>
            </l-tooltip>
            <l-popup>
              {{ resource?.name ?? "" }}
            </l-popup>
          </l-marker>
        </l-map>
      </div>

      <div v-else>
        <GmapMap
          v-if="dialog"
          ref="gmap"
          :center="center"
          :zoom="0"
          map-type-id="terrain"
          :options="{ minZoom: 5, maxZoom: 19 }"
          style="height: 600px; width: 100%"
          class="elevation-11"
          @click="toggleInfoWindow"
        >
          <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="false"
            :draggable="false"
            :label="m.resource?.name ?? ''"
          />

          <GmapInfoWindow
            :options="{
              width: 0,
              height: -25,
            }"
            style="margin: 0; padding: 0; min-width: 300px"
            :opened="infoWindowStatus"
            :position="currentLatLng ? currentLatLng : { lat: 0, lng: 0 }"
            @closeclick="closeInfoWindow"
          >
            <v-container style="width: 100%; height: 100%; padding: 0">
              <v-card flat light>
                <v-card-text>
                  <h3>
                    {{ $t("common.pickLocationConfirm") }}
                  </h3>
                </v-card-text>
                <v-card-text>
                  <v-btn color="blue darken-1" text @click="updatePos">
                    {{ $t("common.save") }}
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-container>
          </GmapInfoWindow>
        </GmapMap>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LControl,
} from "vue2-leaflet";

export default {
  name: "PickLocation",

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LControl,
  },

  props: {
    resource: { default: null },
    leaflet: { type: Boolean, default: false },
  },

  data() {
    return {
      dialog: false,
      map: null,
      tab: null,
      bounds: null,
      infoWindowStatus: false,
      defaultCenter: { lat: 58, lng: 14 },
      center: null,
      currentLatLng: null,
      confirmDelete: false,
      errorMessage: "",

      leafletUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      leafletAttribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      leafletLoaded: false,
      displayLegend: false,

      tabItems: [
        { label: this.$t("signalTest.tab.map"), key: "map" },
        { label: this.$t("signalTest.tab.data"), key: "data" },
      ],

      markers: [],
    };
  },

  computed: {
    google: gmapApi,
  },

  methods: {
    updatePos() {
      this.$emit(
        "update-pos",
        {
          latitude: this.leaflet
            ? this.currentLatLng.lat
            : this.currentLatLng.lat(),
          longitude: this.leaflet
            ? this.currentLatLng.lng
            : this.currentLatLng.lng(),
        },
        this.resource
      );
      this.dialog = false;
      this.center = this.defaultCenter;
      this.map = null;
      this.markers = [];
    },

    updateDrag(event) {
      this.currentLatLng = event.target.getLatLng();
    },

    initMap() {
      // Get current location of the user
      this.currentLatLng = this.defaultCenter;
      this.infoWindowStatus = false;
      this.markers = [];

      // Try to get current gps position of user
      navigator.geolocation.getCurrentPosition(
        (success) => {
          this.defaultCenter = L.latLng(
            success.coords.latitude,
            success.coords.longitude
          );
          this.center = this.defaultCenter;
        },
        (error) => {
          this.errorMessage = error;
        }
      );

      //Adds the resource to be displayed
      if (this.resource && this.resource.latitude && this.resource.longitude) {
        var pos = L.latLng(this.resource.latitude, this.resource.longitude);
        this.markers.push({ position: pos, resource: this.resource });
      }
    },

    closeInfoWindow() {
      this.infoWindowStatus = false;
    },

    toggleInfoWindow(pos) {
      this.currentLatLng = pos.latLng;
      this.infoWindowStatus = !this.infoWindowStatus;
    },

    handleLeafletClick(event) {
      this.currentLatLng = event.latlng;
      this.infoWindowStatus = !this.infoWindowStatus;
    },

    async updateLayout() {
      this.$nextTick(() => {
        this.$refs.lmap.mapObject.invalidateSize();
      });
    },
  },

  async mounted() {
    this.center = this.defaultCenter;

    if (this.leaflet) {
      delete L.Icon.Default.prototype._getIconUrl;
      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      });
      this.leafletLoaded = true;
      return;
    }
    await this.$gmapApiPromiseLazy();
  },

  beforeDestroy() {
    this.dialog = false;
    this.center = this.defaultCenter;
    this.currentLatLng = null;
    this.infoWindowStatus = false;
    this.map = null;
    this.markers = [];
  },

  watch: {
    dialog(val) {
      if (val) {
        this.$nextTick(() => {
          this.initMap();
        });
      }
    },
  },
};
</script>
